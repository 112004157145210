import React from "react";
import Heading from "../../Components/Heading/Heading";
import "./Testimonial.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import TestimonialCard from "../../Components/TestimonialCard/TestimonialCard";

const Testimonial = (props) => {
  const settings = {
    arrows: false,
    dots: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    speed: 5000,
    autoplaySpeed: 5000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1250,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <section id="testimonial">
      <Heading
        highlightText="Testimonials"
        subTitleText="Some reviews given by our customers"
      />

      <Slider {...settings}>
        {props.data &&
          props.data.map((el, ind) => {
            return (
              <TestimonialCard
                key={ind}
                img={el.client_img}
                name={el.name}
                message={el.message}
                ratings={el.ratings}
              />
            );
          })}
      </Slider>
    </section>
  );
};

export default Testimonial;
