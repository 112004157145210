import React, { useState } from "react";
import { urlFor } from "../../client";
import "./HeroSection.css";

const HeroSection = (props) => {
  const [showDesc, setShowDesc] = useState(null);

  return (
    <section
      id="home"
      className="hero_section"
      style={
        props?.data?.main_image
          ? {
              background: `linear-gradient(rgba(0,0,0,0.4), rgba(0,0,0,0.8)), url(${urlFor(
                props?.data?.main_image
              )}) center center/cover`,
              backgroundAttachment: "fixed",
            }
          : null
      }
    >
      <p className="welcome_text">Welcome To</p>
      <h1 className="highlight_name_text">{props?.data?.name}</h1>
      <div className="button_container">
        <button
          className="btn hero_section_btn"
          onClick={() => setShowDesc((prev) => !prev)}
        >
          {showDesc ? "Show Less" : "Learn More"}
        </button>
      </div>
      {showDesc && <p className="desc__text">{props?.data?.description}</p>}
    </section>
  );
};

export default HeroSection;
