import React, { useState, useEffect } from "react";
import "./NumCounterRow.css";
import { urlFor } from "../../client";

const NumCounterRow = (props) => {
  const [productsData, setProductsData] = useState(null);
  const [happyClientsData, setHappyClientsData] = useState(null);
  const [yearOfBusinessData, setYearOfBusinessData] = useState(null);
  const [ratingsData, setRatingsData] = useState(null);

  const [productsCount, setProductsCount] = useState(0);
  const [happyClientsCount, setHappyClientsCount] = useState(0);
  const [yearOfBusinessCount, setYearOfBusinessCount] = useState(0);
  const [ratingsCount, setRatingsCount] = useState(0);

  useEffect(() => {
    if (props.data) {
      for (let i = 0; i < props.data.length; i++) {
        let counterName = props.data[i].counter_name;
        let img = props.data[i].count_image;
        let count = props.data[i].count_number;

        let obj = {
          img: img,
          name: counterName,
          count: count,
        };

        if (counterName === "Products") {
          setProductsData(obj);

          const timer = setInterval(() => {
            setProductsCount((count) => {
              if (count >= obj.count) {
                clearInterval(timer);
                return count;
              } else return count + 1;
            });
          }, 100);
        }
        if (counterName === "Star Ratings") {
          setRatingsData(obj);

          const timer = setInterval(() => {
            setRatingsCount((count) => {
              if (count >= obj.count) {
                clearInterval(timer);
                return obj.count;
              } else return count + 1;
            });
          }, 200);
        }
        if (counterName === "Years of Business") {
          setYearOfBusinessData(obj);

          const timer = setInterval(() => {
            setYearOfBusinessCount((count) => {
              if (count >= obj.count) {
                clearInterval(timer);
                return count;
              } else return count + 1;
            });
          }, 200);
        }
        if (counterName === "Happy Clients") {
          setHappyClientsData(obj);

          const timer = setInterval(() => {
            setHappyClientsCount((count) => {
              if (count >= obj.count) {
                clearInterval(timer);
                return count;
              } else return count + 1;
            });
          }, 1);
        }
      }
    }
  }, [props.data]);

  return (
    <div className="num_counter_section">
      {productsData && (
        <div className="num_counter_card">
          <img src={urlFor(productsData.img)} alt={productsData.name} />
          <span className="counter__text">{productsCount}+</span>
          <span className="counter__name">{productsData.name}</span>
        </div>
      )}

      {happyClientsData && (
        <div className="num_counter_card">
          <img src={urlFor(happyClientsData.img)} alt={happyClientsData.name} />
          <span className="counter__text">{happyClientsCount}+</span>
          <span className="counter__name">{happyClientsData.name}</span>
        </div>
      )}

      {yearOfBusinessData && (
        <div className="num_counter_card">
          <img
            src={urlFor(yearOfBusinessData.img)}
            alt={yearOfBusinessData.name}
          />
          <span className="counter__text">{yearOfBusinessCount}+</span>
          <span className="counter__name">{yearOfBusinessData.name}</span>
        </div>
      )}

      {ratingsData && (
        <div className="num_counter_card">
          <img src={urlFor(ratingsData.img)} alt={ratingsData.name} />
          <span className="counter__text">{ratingsCount}+</span>
          <span className="counter__name">{ratingsData.name}</span>
        </div>
      )}
    </div>
  );
};

export default NumCounterRow;
