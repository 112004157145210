import React from "react";
import StarIcon from "@mui/icons-material/Star";
import StarHalfIcon from "@mui/icons-material/StarHalf";

const StarComponent = (props) => {
  return (
    <div className="star_container">
      <StarIcon style={{ color: "#fab005" }} />
      <StarIcon style={{ color: "#fab005" }} />
      <StarIcon style={{ color: "#fab005" }} />
      <StarIcon style={{ color: "#fab005" }} />
      <StarHalfIcon style={{ color: "#fab005" }} />
    </div>
  );
};

export default StarComponent;
