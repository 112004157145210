import React, { useState } from "react";
import "./Navbar.css";

const Navbar = () => {
  const [navActive, setNavActive] = useState(false);

  return (
    <header className="header">
      <div className="logo_container">
        <h1>Pankaj Nandwani</h1>
      </div>
      <nav className="nav">
        <ul className={`nav__link_container ${navActive ? "nav_active" : ""}`}>
          <li>
            <a href="#home" className="nav__links">
              Home
            </a>
          </li>
          <li>
            <a href="#about" className="nav__links">
              About
            </a>
          </li>

          <li>
            <a href="#products" className="nav__links">
              Products
            </a>
          </li>

          <li>
            <a href="#testimonial" className="nav__links">
              Testimonial
            </a>
          </li>

          <li>
            <a href="#contact" className="nav__links">
              Contact
            </a>
          </li>
        </ul>
      </nav>
      <div
        className={`burger ${navActive ? "toggle" : ""}`}
        onClick={() => setNavActive((prev) => !prev)}
      >
        <div className="line line__1"></div>
        <div className="line line__2"></div>
        <div className="line line__3"></div>
      </div>
    </header>
  );
};

export default Navbar;
