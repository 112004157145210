import React, { useRef } from "react";
import "./Contact.css";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import EmailIcon from "@mui/icons-material/Email";
import Heading from "../../Components/Heading/Heading";

const Contact = (props) => {
  const firstNameRef = useRef(null);
  const lastNameRef = useRef(null);
  const emailRef = useRef(null);
  const messageRef = useRef(null);

  const handleSubmit = () => {
    setTimeout(() => {
      firstNameRef.current.value = "";
      lastNameRef.current.value = "";
      emailRef.current.value = "";
      messageRef.current.value = "";
    }, 5000);
  };

  return (
    <div id="contact">
      <Heading highlightText="Contact" subTitleText="Send a Message to me" />
      <form
        action="https://formspree.io/f/myyryelo"
        method="POST"
        name="contact_page"
        className="contact__form"
        onSubmit={() => handleSubmit()}
      >
        <div className="form__container">
          <h4>SEND US A MESSAGE</h4>
          <div className="box">
            <label htmlFor="firstname">First name</label> <br />
            <input
              ref={firstNameRef}
              id="firstname"
              name="firstname"
              type="text"
              placeholder="First name"
              required
            />
          </div>
          <div className="box">
            <label htmlFor="lastname">Last name</label> <br />
            <input
              ref={lastNameRef}
              id="lastname"
              name="lastname"
              type="text"
              placeholder="Last name"
              required
            />
          </div>

          <div className="email">
            <label htmlFor="emailid">Email</label> <br />
            <input
              ref={emailRef}
              id="emailid"
              type="email"
              name="email"
              placeholder="Enter your mail"
              required
            />
          </div>
          <div className="message">
            <label htmlFor="query">Message</label> <br />
            <textarea name="message" id="query" ref={messageRef}></textarea>
          </div>

          <input type="submit" value="Submit" className="submit--here" />
        </div>
        <div className="image">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d5408.810260356521!2d75.85989626333381!3d22.716795532784573!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3962fd0e0e27bf47%3A0x917e50e7e31c46c7!2sSiyaganj%2C%20Indore%2C%20Madhya%20Pradesh%20452007!5e0!3m2!1sen!2sin!4v1704095275203!5m2!1sen!2sin"
            style={{ border: "0" }}
            title="Home Address"
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </form>

      <div className="icon">
        <div className="location">
          <a
            style={{ marginTop: "25px" }}
            href="https://maps.app.goo.gl/knmGRYK5j64nWofV8"
          >
            <div className="box">
              <LocationOnIcon />
            </div>
          </a>

          <span className="contact--info">Our Location</span>
          <span className="details">{props.location}</span>
        </div>

        <div className="phone">
          <a href="tel:+91 9303233346">
            <div className="box">
              <LocalPhoneIcon />
            </div>
          </a>

          <span className="contact--info">Our Phone</span>
          <span className="details">+91 {props.phone} </span>
        </div>

        <div className="emailicon">
          <a href="mailto:pankajnandwani22@gmail.com?&amp;subject=Hey There, I am a user from your website.">
            <div className="box">
              <EmailIcon />
            </div>
          </a>
          <span className="contact--info">Our Email id</span>
          <span className="details">{props.email}</span>
        </div>
      </div>
    </div>
  );
};

export default Contact;
