import React from "react";
import Heading from "../../Components/Heading/Heading";
import "./ProductsCarousel.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { urlFor } from "../../client";

const ProductsCarousel = (props) => {
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1250,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <section id="products">
      <Heading
        highlightText="Products"
        subTitleText="Some of our products listed below"
      />

      <Slider {...settings}>
        {props.data &&
          props.data.map((el, ind) => {
            return (
              <div key={ind} className="product__card">
                <img src={urlFor(el.product_image)} alt={el.name} />
              </div>
            );
          })}
      </Slider>
    </section>
  );
};

export default ProductsCarousel;
