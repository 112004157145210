import React from "react";
import { urlFor } from "../../client";
import "./TestimonialCard.css";
import StarComponent from "../StarComponent/StarComponent";

const TestimonialCard = (props) => {
  return (
    <div className="testimonial_card" key={props.key}>
      <div className="testimonial_card_innerBox">
        {props.img && <img src={urlFor(props.img)} alt="client" />}
        {props.ratings && <StarComponent ratings={props.ratings} />}
        {props.name && <span className="testimonial__name">{props.name}</span>}
        {props.message && (
          <span className="testimonial__message">{props.message}</span>
        )}
      </div>
    </div>
  );
};

export default TestimonialCard;
