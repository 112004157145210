import React from "react";
import "./About.css";
import { urlFor } from "../../client";
import Heading from "../../Components/Heading/Heading";

const About = (props) => {
  return (
    <section id="about">
      <Heading
        highlightText="About"
        subTitleText="Know more about Founder and Business"
      />

      <div className="about_section">
        <div className="user__content">
          <span className="sub__title">{props?.data?.description}</span>
          <div className="about_link_btn_container">
            <a className="links__btn" href="#products">
              View Products
            </a>
          </div>
        </div>
        <div className="user__image">
          {props.data && (
            <img src={urlFor(props?.data?.about_image)} alt="pankaj nandwani" />
          )}
        </div>
      </div>
    </section>
  );
};

export default About;
