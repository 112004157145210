import React from "react";
import "./Footer.css";
import InstagramIcon from "@mui/icons-material/Instagram";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import FacebookIcon from "@mui/icons-material/Facebook";
import CallIcon from "@mui/icons-material/Call";
import EmailIcon from "@mui/icons-material/Email";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

const Footer = (props) => {
  return (
    <footer className="footer">
      <div className="footer__links__container">
        <div className="footer__cols">
          <div className="footer__logo_container">
            <h2>Pankaj Nandwani</h2>
          </div>
          <div className="footer__details">
            <CallIcon />
            <a href="tel:+91 9303233346">+91 {props.phone}</a>
          </div>
          <div className="footer__details">
            <EmailIcon />
            <a href="mailto:pankajnandwani22@gmail.com?&amp;subject=Hey There, I am a user from your website.">
              {props.email}
            </a>
          </div>
          <div className="footer__details">
            <LocationOnIcon />
            <a href="https://maps.app.goo.gl/knmGRYK5j64nWofV8">
              {props.location}
            </a>
          </div>
        </div>

        <div className="footer__cols quick__links__col">
          <h4>Quick Links</h4>
          <div className="footer__details">
            <ArrowRightIcon />
            <a href="#about">About</a>
          </div>
          <div className="footer__details">
            <ArrowRightIcon />
            <a href="#products">Products</a>
          </div>
          <div className="footer__details">
            <ArrowRightIcon />
            <a href="#testimonial">Testimonials</a>
          </div>
          <div className="footer__details">
            <ArrowRightIcon />
            <a href="#contact">Contact</a>
          </div>
        </div>

        <div>
          <h3 className="social_media_heading">Social Media</h3>
          <div className="social_media_container">
            <a href="https://www.linkedin.com/in/pankaj-nandwani-24365220b/">
              <LinkedInIcon />
            </a>

            <a href="https://wa.me/+919303233346">
              <WhatsAppIcon />
            </a>

            <a href="https://www.instagram.com/pankajnandwanicom/">
              <InstagramIcon />
            </a>

            <a href="https://www.facebook.com/people/Pankaj-Nandwani/100093345647094/?is_tour_dismissed=true">
              <FacebookIcon />
            </a>
          </div>
        </div>
      </div>

      <div className="footer__copyright">
        Copyright © {new Date().getFullYear()} | All Rights Reserved by Pankaj
        Nandwani
      </div>
    </footer>
  );
};

export default Footer;
