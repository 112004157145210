import React, { useState, useEffect } from "react";
import { client } from "./client";
import Navbar from "./Containers/Navbar/Navbar";
import HeroSection from "./Containers/HeroSection/HeroSection";
import About from "./Containers/About/About";
import ProductsCarousel from "./Containers/ProductsCarousel/ProductsCarousel";
import Testimonial from "./Containers/Testimonial/Testimonial";
import NumCounterRow from "./Components/NumCounterRow/NumCounterRow";
import Contact from "./Containers/Contact/Contact";
import Footer from "./Containers/Footer/Footer";
import Loader from "./Components/Loader/Loader";

const App = () => {
  const [showLoader, setShowLoader] = useState(true);
  const [heroSectionData, setHeroSectionData] = useState(null);
  const [aboutData, setAboutData] = useState(null);
  const [testimonialsData, setTestimonialsData] = useState(null);
  const [productsData, setProductsData] = useState(null);
  const [countersData, setCountersData] = useState(null);
  const [contactData, setContactData] = useState(null);

  useEffect(() => {
    setTimeout(() => {
      setShowLoader(false);
    }, 1500);
  }, []);

  useEffect(() => {
    const hero_query = '*[_type == "headline"]';
    client.fetch(hero_query).then((data) => setHeroSectionData(data[0]));

    const about_query = '*[_type == "about"]';
    client.fetch(about_query).then((data) => setAboutData(data[0]));

    const contact_query = '*[_type == "contact"]';
    client.fetch(contact_query).then((data) => setContactData(data[0]));

    const testimonials_query = '*[_type == "testimonials"]';
    client.fetch(testimonials_query).then((data) => setTestimonialsData(data));

    const counter_query = '*[_type == "counter"]';
    client.fetch(counter_query).then((data) => setCountersData(data));

    const products_query = '*[_type == "products"]';
    client.fetch(products_query).then((data) => setProductsData(data));

    setTimeout(() => {
      setShowLoader(false);
    }, 1500);
  }, []);
  // {data && <img src={urlFor(aboutData.about_image)} alt={data[0].name} />}

  return (
    <>
      {showLoader && <Loader />}

      <Navbar />
      <main>
        <HeroSection data={heroSectionData && heroSectionData} />
        <About data={aboutData && aboutData} />
        <ProductsCarousel data={productsData && productsData} />
        <Testimonial data={testimonialsData && testimonialsData} />
        <NumCounterRow data={countersData && countersData} />

        <Contact
          email={contactData && contactData.email}
          phone={contactData && contactData.phone}
          location={contactData && contactData.location}
        />
      </main>
      <Footer
        email={contactData && contactData.email}
        phone={contactData && contactData.phone}
        location={contactData && contactData.location}
      />
    </>
  );
};

export default App;
